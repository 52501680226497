<template>
	<VOverlay
		:title="$t('mb.newDocument.title')"
		:size="2"
		:isVCentered="false"
		:closeOnBackgroundClick="true"
		:hasBackground="true"
		:modelValue="true"
		@update:modelValue="overlayClosed"
		:modalHasSharpCorner="true"
		@keydown.meta.s.prevent.stop="submit"
	>
		<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('mb.newDocument.whatName')" />

		<VInputString
			:isRequired="true"
			v-model:valueModel="docNameValue"
			:label="$t('mb.newDocument.nameInputLabel')"
			:trim="true"
			@update:valueModel="markModified"
		/>

		<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('mb.newDocument.giveDescription')" />

		<VTextarea
			v-model="docDescription"
			:placeholder="$t('mb.newDocument.giveDescription')"
			class="has-margin-bottom-4"
			:isNarrow="true"
		/>

		<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('mb.newDocument.whatType')" />

		<VField
			:label="$t('mb.newDocument.selectType')"
			:isFloating="true"
			:class="{ 'is-active': typeValue }"
		>
			<VSelect :options="typeOptions" v-model="typeValue" />
		</VField>

		<template #footer>
			<VButton
				:text="$t('mb.newDocument.submitButton')"
				icon="chevron-right"
				:iconOnRight="true"
				classes="button-modal-footer"
				:isDisabled="!isValid"
				@clicked="submit"
			/>
		</template>
	</VOverlay>
</template>

<script>
import { mapGetters } from 'vuex';
import { getStoreAction, getStoreGetter, getStoreMutation } from '@assets/scripts/store/config';
import Helpers from '@assets/scripts/helpers';

export default {
	name: 'NewMetaDocument',
	data: function () {
		const typeOptions = [];

		return {
			typeOptions,
			typeValue: false,
			docNameValue: '',
			docDescription: '',
		};
	},
	computed: {
		...mapGetters({
			modified: getStoreGetter('MODIFIED', 'MB'),
		}),
		isValid: function () {
			return this.typeValue && this.docNameValue.trim();
		},
	},
	mounted: async function () {
		const schemaTypes = await this.$store.dispatch(
			getStoreAction('GET_SCHEMA_TYPES', 'MB')
		);

		if (schemaTypes) {
			this.typeOptions = Helpers.mapObjectArrayForSelect(schemaTypes, {
				key: 'key',
				text: 'value',
			});
		}
	},
	methods: {
		markModified: function () {
			// no need to trigger this mutation more than once
			if (this.modified) return;

			// mark document as modified whenever a
			// change is made
			this.$store.commit(
				getStoreMutation('MARK_MODIFIED', 'MB')
			);
		},
		overlayClosed: function () {
			this.$store.dispatch(
				getStoreAction('TOGGLE_CREATE_NEW', 'MB'),
				false
			);
		},
		submit: function () {
			if (!this.isValid) return;

			this.$store.dispatch(getStoreAction('NEW_META_DOCUMENT', 'MB'), {
				name: this.docNameValue.trim(),
				description: this.docDescription.trim(),
				type: this.typeValue,
			});
		},
	},
};
</script>
