<template>
	<div class="app-layer" :class="{ 'is-active': metadataActive }">
		<TheMetaDocumentDetails v-if="metadataActive" />
	</div>
	<div
		class="overlay-layer app-layer"
		:class="{ 'is-active': !metadataActive }"
	>
		<NewMetaDocument v-if="showCreateNew" />

		<Suspense>
			<TheMetadataOverview v-show="showOverview" />
		</Suspense>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getStoreAction, getStoreGetter } from '@assets/scripts/store/config';
import usePermission from '@assets/scripts/composables/usePermission';

import TheMetaDocumentDetails from '@modules/MetaDocumentBuilder/materials/structures/TheMetaDocumentDetails.vue';
import TheMetadataOverview from '@modules/MetaDocumentBuilder/materials/TheMetadataOverview.vue';
import NewMetaDocument from '@modules/MetaDocumentBuilder/materials/structures/NewMetaDocument.vue';

export default {
	name: 'TheMetadataBuilder',
	components: {
		TheMetaDocumentDetails,
		TheMetadataOverview,
		NewMetaDocument
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate whether the form to
			 * create a new document should be displayed
			 */
			showCreateNew: getStoreGetter('SHOW_CREATE_NEW', 'MB'),
			/**
			 * Boolean to indicate whether a metadata is
			 * currently active
			 */
			metadataActive: getStoreGetter('METADATA_ACTIVE', 'MB'),
		}),
		/**
		 * Boolean to indicate whether the current
		 * user can read (view) metadata documents
		 */
		canReadMetaDoc: function () {
			return usePermission('Read', 'MetaDocumentBuilder');
		},
		showOverview: function () {
			return !this.showCreateNew && !this.metadataActive && this.canReadMetaDoc;
		}
	},
	unmounted: function () {
		// reset store to default state when page is unmounted
		this.$store.dispatch(getStoreAction('RESET', 'MB'));
	},
};
</script>
