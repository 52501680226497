<template>
	<VOverlay
		:hasBackground="false"
		:hasJsonButton="false"
		:hasCloseButton="false"
		:closeOnBackgroundClick="false"
		:isLarge="true"
		:isVCentered="false"
		:hasFullwidthBody="true"
		:hasScrollableContent="true"
		v-model="overlayIsActive"
		:title="$t('mb.metadataList.title')"
		:useFocusTrap="false"
		:closeWithEscape="false"
	>
		<Suspense>
			<MetadataList />
		</Suspense>
	</VOverlay>
</template>

<script>
import MetadataList from '@modules/MetaDocumentBuilder/materials/structures/MetadataList.vue';

export default {
	name: 'TheMetadataOverview',
	components: {
		MetadataList,
	},
	data: function () {
		return {
			overlayIsActive: true,
		};
	},
};
</script>
