<template>
	<div class="content-header has-bottom-divider has-padding-top-3 has-margin">
		<div class="field is-grouped is-flex-grow-1 is-align-items-end">
			<VField
				:label="$t('mb.metadataList.schemaTypes')"
				:isFloating="true"
				class="has-margin-right-1"
				:class="{ 'is-active': schemaTypeValue }"
			>
				<VSelect :options="schemaTypeOptions" v-model="schemaTypeValue" />
			</VField>

			<VLink
				v-if="canEditMetaDoc"
				:text="$t('mb.metadataList.addMetadata')"
				icon="plus"
				:hasUnderline="true"
				@click.prevent.stop="createNewClicked"
				class="has-margin-right-3 has-margin-bottom-05"
			/>

			<VSearch v-model="search" />
		</div>
	</div>

	<div class="scrollable-content">
		<SortableDataTable
			:scrollable="false"
			:rowClickedFn="rowClicked"
			defaultSort="last_time_edited"
			sortDirection="DESC"
			:data="filteredData"
			:columns="columns"
			:emptyText="$t('mb.metadataList.table.noResults')"
		/>
	</div>
</template>

<script>
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { reactive, toRefs } from '@vue/reactivity';
import {
	getStoreAction,
	getStoreMutation,
} from '@assets/scripts/store/config';
import { formatForTable } from '@modules/MetaDocumentBuilder/components/metadata-document';
import Helpers from '@assets/scripts/helpers';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { useCreateNewOnKeydown } from '@assets/scripts/composables/useCreateNewOnKeydown';
import usePermission from '@assets/scripts/composables/usePermission';
import { GET_METADATA_DOCUMENTS } from '@modules/MetaDocumentBuilder/endpoints';

export default {
	name: 'MetadataList',
	data: function () {
		return {
			// default value for metadataDocument schemaType filter
			schemaTypeValue: this.$t('general.all'),
			search: '',
		};
	},
	async setup () {
		const store = useStore();
		const { t } = useI18n();

		const state = reactive({
			metadataDocuments: [],
			/**
			 * Boolean to indicate whether the current
			 * user can create meta documents
			 */
			canEditMetaDoc: usePermission('Upsert', 'MetaDocumentBuilder'),
		});

		const createNewClicked = () => {
			// create new metadataDocument
			store.dispatch(
				getStoreAction('TOGGLE_CREATE_NEW', 'MB'),
				true
			);
		};
		if(state.canEditMetaDoc) {
			useCreateNewOnKeydown(createNewClicked);
		}

		const loadDocuments = async () => {
			// get all metadata documents
			state.metadataDocuments = await useApiAsync(GET_METADATA_DOCUMENTS);
		};

		// subscribe to store action
		const unsubscribeAction = store.subscribeAction({
			after: async ({ type }) => {
				// check action type
				if (type === getStoreAction('UPDATE_DOCUMENT_LIST', 'MB')) {
					// reload document list if needed
					await loadDocuments();
				}
			}
		});

		await loadDocuments();

		// values for metadataDocument schemaType filter
		let schemaTypeOptions = [];

		const schemaTypes = await store.dispatch(
			getStoreAction('GET_SCHEMA_TYPES', 'MB')
		);

		if (schemaTypes) {
			schemaTypeOptions = Helpers.mapObjectArrayForSelect(schemaTypes, {
				key: 'key',
				text: 'value',
			});
		}

		// add 'All' as first option
		schemaTypeOptions.unshift({
			value: t('general.all'),
			text: t('general.all'),
		});

		return {
			...toRefs(state),
			schemaTypeOptions,
			unsubscribeAction,
			createNewClicked,
		};
	},
	computed: {
		columns: function() {
			return [
				{
					label: this.$t('mb.metadataList.table.columns.name'),
					field: 'name',
					sortable: true,
					searchable: true,
					cellClass: 'has-text-weight-semibold',
					// width: '30%',
				},
				{
					label: this.$t('mb.metadataList.table.columns.schemaType'),
					field: 'type',
					sortable: true,
					searchable: true,
					// width: '15%',
				},
				{
					label: this.$t('mb.metadataList.table.columns.last_time_edited'),
					field: 'last_time_edited',
					sortable: true,
					searchable: true,
					isDate: true,
					// width: '25%',
				},
				{
					label: this.$t('mb.metadataList.table.columns.status'),
					field: 'status',
					sortable: true,
					searchable: true,
					// width: '15%',
				},
				{
					label: '',
					field: 'edit',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						edit: {
							title: this.$t('general.edit'),
							isTool: true,
							icon: 'edit',
						},
						view: {
							title: this.$t('general.inspect'),
							isTool: true,
							icon: 'eye',
						}
					},
					click: this.rowClicked,
				},
				{
					label: '',
					field: 'delete',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						href: '',
						title: this.$t('general.delete'),
						isTool: true,
						class: '',
						icon: 'delete',
					},
					click: this.deleteClicked,
				},
			];
		},
		tableFields: function () {
			return formatForTable(this.metadataDocuments);
		},
		searchableCols: function () {
			return Helpers.getSearchableColumns(this.columns);
		},
		filteredData: function () {
			// loop over all metadataDocuments
			const newData = this.tableFields.filter((row) => {
				// filter on Doc type
				return  Helpers.filterByValue(
					row,
					'type',
					this.schemaTypeValue,
					this.$t('general.all')
				);
			});

			// return if no rows match
			if (this.search.length === 0 || newData.length === 0) return newData;

			// filter on search string
			return Helpers.filterByString(
				newData,
				this.search,
				this.searchableCols
			);
		},
	},
	methods: {
		rowClicked({ guid }) {
			// dispatch action to show clicked metadataDocument
			this.$store.dispatch(
				getStoreAction('LOAD_AND_SHOW_META_DOCUMENT', 'MB'),
				guid,
			);
		},
		deleteClicked({ guid }) {
			// ask confirmation to delete metadataDocument
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('mb.metadataList.delete.confirm.title'),
				body: this.$t('mb.metadataList.delete.confirm.body'),
				confirmButtonText: this.$t(
					'mb.metadataList.delete.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// dispatch action to delete clicked metadataDocument
					this.$store.dispatch(
						getStoreAction('DELETE_DOCUMENT', 'MB'),
						guid,
					);
				},
			});
		},
		sortByDate(a, b, isAsc) {
			return Helpers.date.sortByIsoDate(a.time, b.time, isAsc);
		},
	},
	unmounted: function () {
		// unsubscribe from actions
		this.unsubscribeAction();
	},
};
</script>
