<template>
	<div class="page-with-options-wrapper">
		<TheAppOptions
			:backButtonText="$t('mb.docDetails.backToOverview')"
			:backClickedFn="closeDocument"
			:nameLabel="$t('mb.docDetails.docName')"
			v-model:name="docName"
			@update:name="updateName"
			:saveClickedFn="saveDocument"
			:editMode="canBeEdited"
			:canBeSaved="canBeSaved"
			:showButtons="showButtons"
			:canBePublished="canBePublished"
			:publishClickedFn="publishDocument"
			:canBeValidated="canBeValidated"
			:validateClickedFn="validateMetaDocument"
			:validationErrorListItemIsClickable="false"
			:trimName="true"
		/>

		<div class="page-content js-page-content">
			<ContentBlock
				:size="3"
				:title="$t('mb.docDetails.documentSettings')"
				:hasBorderUnderTitle="true"
			>
				<div class="has-padding-top-1">
					<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('mb.docDetails.description')" />

					<VTextarea
						v-model="document.description"
						:placeholder="$t('mb.docDetails.description')"
						class="has-margin-bottom-4"
						:isNarrow="true"
						@change="markMetaDocAsUpdated"
						:disabled="!canBeEdited"
					/>
				</div>
			</ContentBlock>
			<ContentBlock
				:size="3"
				:title="$t('general.fields')"
				:hasFullwidthBody="true"
			>
				<div class="is-flex has-bottom-divider has-padding-left-5 has-padding-right-5 has-margin-bottom-075">
					<VLink
						:text="$t('mb.docDetails.addField')"
						icon="plus"
						:hasUnderline="true"
						@click.prevent.stop="addNewFieldClicked"
						class="has-margin-bottom-05 has-margin-right-3"
						:isDisabled="!canBeEdited"
					/>

					<VSearch v-model="search" />
				</div>

				<SortableDataTable
					:scrollable="false"
					:stickyHeader="false"
					:rowClickedFn="inspectClicked"
					default-sort="order"
					default-sort-direction="ASC"
					:data="filteredData"
					:columns="columns"
					:emptyText="$t('mb.docDetails.table.noResults')"
					:draggable="canBeDragged"
					@dragstart="tableDragStart"
					@dragover="tableDragOver"
					@dragleave="tableDragLeave"
					@drop="tableDrop"
				/>
			</ContentBlock>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
	getStoreGetter,
	getStoreMutation,
	getStoreAction,
} from '@assets/scripts/store/config';
import { log } from '@assets/scripts/components/notifications';

import TheAppOptions from '@materials/structures/TheAppOptions.vue';

import Field from '@assets/scripts/components/field';
import Helpers from '@assets/scripts/helpers';
import { formatForMetaDocumentTable } from '@modules/MetaDocumentBuilder/components/metadata-document';

export default {
	name: 'TheMetaDocumentDetails',
	components: {
		TheAppOptions,
	},
	data: function () {
		return {
			docName: '',
			search: '',
			draggingRow: null,
		};
	},
	mounted: function () {
		this.docName =
			this.document && this.document.name
				? this.document.name
				: '';

		// clear validation when document is loded
		this.$store.dispatch(getStoreAction('CLEAR_VALIDATION'));
	},
	computed: {
		columns: function() {
			const columns = [
				{
					label: '',
					field: 'order',
					sortable: true,
					searchable: false,
					visible: false,
					default: '',
					customSort: this.sortBySortOrder,
				},
				{
					label: this.$t('mb.docDetails.table.columns.name'),
					field: 'field_name',
					searchable: true,
					cellClass: 'has-text-weight-semibold',
					leveled: true,
				},
				{
					label: this.$t('mb.docDetails.table.columns.type'),
					field: 'field_type',
					searchable: true,
				},
				{
					label: this.$t('mb.docDetails.table.columns.default'),
					field: 'default_value',
					searchable: true,
				},
				{
					label: this.$t('mb.docDetails.table.columns.validation'),
					field: 'validation',
					searchable: true,
				},
				{
					label: this.$t('mb.docDetails.table.columns.required'),
					field: 'required_field',
					searchable: true,
				},
				{
					label: '',
					field: 'edit',
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						href: '',
						title: this.canBeEdited
							? this.$t('general.edit')
							: this.$t('general.inspect'),
						isTool: true,
						class: '',
						icon: this.canBeEdited ? 'edit' : 'eye',
					},
					click: this.inspectClicked,
				},
			];
			// add delete column if applicable
			if (this.canBeEdited) {
				columns.push({
					label: '',
					field: 'delete',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						href: '',
						title: this.$t('general.delete'),
						isTool: true,
						class: '',
						icon: 'delete',
					},
					click: this.deleteClicked,
				});
			}

			return columns;
		},
		...mapGetters({
			/**
			 * Boolean to indicate whether user modified the document
			 */
			modified: getStoreGetter('MODIFIED', 'MB'),
			/**
			 * Gets currently active Document
			 */
			document: getStoreGetter('CURRENT_META_DOCUMENT', 'MB'),
			/**
			 * Boolean to indicate whether current document
			 * can be edited
			 */
			canBeEdited: getStoreGetter('CAN_BE_EDITED', 'MB'),
			/**
			 * Boolean to indicate whether current document
			 * can be saved
			 */
			canBeSaved: getStoreGetter('CAN_BE_SAVED', 'MB'),
			/**
			 * Boolean to indicate whether current document
			 * can be published
			 */
			canBePublished: getStoreGetter('CAN_BE_PUBLISHED', 'MB'),
		}),
		showButtons: function () {
			return this.canBeEdited;
		},
		canBeValidated: function () {
			return this.canBeEdited || this.canBePublished;
		},
		tableFields: function () {
			return formatForMetaDocumentTable(this.document.elements);
		},
		searchableCols: function () {
			return Helpers.getSearchableColumns(this.columns);
		},
		filteredData: function () {
			const data = this.tableFields;

			// return if no search active, or no current rows
			if (this.search.length === 0 || data.length === 0) return data;

			// filter on search string
			const matches = Helpers.filterByString(
				data,
				this.search,
				this.searchableCols
			);

			// also add ancestors of matches to matches
			if (matches.length < this.tableFields.length) {
				Field.addAncestorsOfMatches(
					matches,
					this.tableFields,
					'name'
				);
			}

			return matches;
		},
		canBeDragged: function () {
			return this.search === '';
		},
		orderForNewField: function () {
			return this.document.elements.reduce((prev, curr) => {
				return Math.min(prev, curr.order - 1);
			}, -1);
		}
	},
	methods: {
		markMetaDocAsUpdated: function () {
			// mark meta document as modified whenever a
			// change is made
			this.$store.commit(getStoreMutation('MARK_MODIFIED', 'MB'));
		},
		inspectClicked(row) {
			// open drawer with field information
			this.$store.commit(getStoreMutation('OPEN_DRAWER'), {
				type: 'metadataDocumentFieldDetails',
				data: {
					key: row.key + 1, // adding 1 to avoid problems with 0
				},
			});
		},
		closeDocument: function () {
			const closeOverlay = () => {
				// dispatch action to unset document
				this.$store.dispatch(getStoreAction('UNSET_META_DOCUMENT', 'MB'));
			};

			if (!this.modified) {
				// close immediately if config has not been modified
				closeOverlay();
			} else {
				// ask confirmation before closing if a change has been made
				// to the Document
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t('mb.docDetails.close.confirm.title'),
					body: this.$t('mb.docDetails.close.confirm.body'),
					confirmButtonText: this.$t(
						'mb.docDetails.close.confirm.confirmButtonText'
					),
					confirmFn: () => {
						// close after confirmation
						closeOverlay();
					},
				});
			}
		},
		validateMetaDocument: function () {
			// dispatch action to Validate document
			this.$store.dispatch(
				getStoreAction('VALIDATE_CURRENT_META_DOCUMENT', 'MB')
			);
		},
		saveDocument: function () {
			this.$store.dispatch(
				getStoreAction('SAVE_CURRENT_META_DOCUMENT', 'MB')
			);
		},
		publishDocument: function () {
			// ask confirmation before publishing
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('mb.docDetails.publish.confirm.title'),
				body: this.$t('mb.docDetails.publish.confirm.body'),
				confirmButtonText: this.$t(
					'mb.docDetails.publish.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// dispatch action to publish meta document
					this.$store.dispatch(
						getStoreAction('PUBLISH_CURRENT_META_DOCUMENT', 'MB')
					);
				},
			});
		},
		deleteClicked({ key }) {
			// ask confirmation to delete field
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('mb.docDetails.delete.confirm.title'),
				body: this.$t('mb.docDetails.delete.confirm.body'),
				confirmButtonText: this.$t(
					'mb.docDetails.delete.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// do delete after confirmation
					this.document.elements.splice(key, 1);
					this.markMetaDocAsUpdated();
				},
			});
		},
		updateName: function (newName) {
			// commit mutation in store
			this.$store.commit(getStoreMutation('SET_NAME', 'MB'), newName);
		},
		addNewFieldClicked() {
			// open drawer with sort order for new field
			this.$store.commit(getStoreMutation('OPEN_DRAWER'), {
				type: 'metadataDocumentFieldDetails',
				data: {
					order: this.orderForNewField
				}
			});
		},
		sortBySortOrder(a, b) {
			return Field.orderParentChildArrayBySortOrder(a, b, this.document.elements);
		},
		// called when dragging in table starts
		tableDragStart(payload) {
			this.draggingRow = payload.row;
		},
		// called when row in table is dragged over another row
		tableDragOver(payload) {
			payload.event.target.closest('tr').classList.add('is-selected');
			payload.event.preventDefault();
		},
		tableDragLeave(payload) {
			payload.event.target.closest('tr').classList.remove('is-selected');
			payload.event.preventDefault();
		},
		// called when dragged row in table is dropped in new position
		tableDrop(payload) {
			payload.event.target.closest('tr').classList.remove('is-selected');
			const { row: droppedOnRow } = payload;

			// do nothing if dropped in original location
			if (this.draggingRow === droppedOnRow) return;

			// check if dragged field and field on dropped location
			// are siblings. Show error if not.
			if (!Field.fieldsAreSiblings(this.draggingRow.name, droppedOnRow.name)) {
				// throw error
				log(this.$t('mb.error.canNotDropHere'), 'danger');
				return;
			}

			// update sort order in document
			this.updateSortOrder(
				this.draggingRow.order,
				droppedOnRow.order
			);
		},
		updateSortOrder(oldPos, newPos) {
			// do nothing if new position is same as
			// old position
			if (oldPos === newPos) return;

			// check if row was moved either up or down
			const movedUp = oldPos > newPos;

			// loop over all sorting rules
			this.document.elements.forEach((row) => {
				const { order } = row;

				// set moved row to new position
				if (order === oldPos) {
					row.order = newPos;
				} else if (
					// check if moved row was moved up, and
					// current row was sorted above the old position
					// and below or equal to the new position
					movedUp &&
					row.order < oldPos &&
					row.order >= newPos
				) {
					// increase position by 1
					row.order++;
				} else if (
					// check if moved row was moved down, and
					// current row was sorted below the old position
					// and above or equal to the new position
					!movedUp &&
					row.order > oldPos &&
					row.order <= newPos
				) {
					// decrease position by 1
					row.order--;
				}
			});

			this.markMetaDocAsUpdated();
		},
	},
};
</script>
